import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataAccessService } from "@kells/apis/data-access";
import { ImageType } from "@kells/clinic-one/apis";
import { FindingsByImage, FindingsByTooth } from "@kells/interfaces/finding";
import { KellsImageBase } from "@kells/interfaces/image";
import { isDefined } from "@kells/utils/js";
import { keepDefined } from "@kells/utils/observable/observable-operators";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, tap } from "rxjs/operators";
import { SubSink } from "subsink";
import { XRayImageComponent } from "../xray-carousel/xray-image/xray-image.component";

export interface XRayDialogData {
  image?: KellsImageBase;
  imageUrl?: string;
  findingsByTooth: FindingsByTooth;
  findingsByImage: FindingsByImage;
  xrayWidth: number;
  xrayHeight: number;
  showCaries: boolean;
  showBoneloss: boolean;
  showPlaque: boolean;
  showFracture: boolean;
  showGumRecession: boolean;
  showGumInflammation: boolean;
  index: number;
  totalCount: number;
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "xray-dialog",
  templateUrl: "./xray-dialog.component.html",
  styleUrls: ["./xray-dialog.component.scss"],
})
export class XRayDialogComponent implements OnInit, OnDestroy {
  public image?: KellsImageBase;
  public imageUrl?: string;
  public findingsByTooth: FindingsByTooth;
  public findingsByImage: FindingsByImage;
  private _subs: SubSink = new SubSink();
  xrayImageUrl: Subject<string> = new Subject<string>();
  xrayWidth: number;
  xrayHeight: number;
  public index: number;
  public totalCount: number;

  @ViewChild("xray") xray: XRayImageComponent;

  private imageContainerWidthSubject = new Subject<number>();
  imageContainerWidth$: Observable<number> = this.imageContainerWidthSubject
    .asObservable()
    .pipe(keepDefined(), distinctUntilChanged()); //.pipe(map((str: any) => Number.parseInt((str as string).split('px')[0])));

  private imageContainerHeightSubject = new Subject<number>();
  imageContainerHeight$: Observable<number> = this.imageContainerHeightSubject
    .asObservable()
    .pipe(keepDefined(), distinctUntilChanged()); //.pipe(map((str:any) => Number.parseInt((str as string).split('px')[0])));

  containterDimensions$ = combineLatest([
    this.imageContainerWidth$.pipe(keepDefined(), distinctUntilChanged()),
    this.imageContainerHeight$.pipe(keepDefined(), distinctUntilChanged()),
  ]).pipe(
    tap(([containerHeight, containerWidth]: [number, number]) => {
      // this.xray.nativeElement.setAttribute('height', containerHeight + 'px');
      // this.xray.nativeElement.setAttribute('width', containerWidth + 'px');
    })
  );

  public get title(): string {
    const imageType =
      this.findingsByImage.imageType === ImageType.Xray ? "XRay" : "Photo";

    return `Viewing ${imageType} ${this.index + 1} of ${this.totalCount}`;
  }

  showCaries = true;
  showBoneloss = true;
  showFracture = false;
  showPlaque = false;
  showGumRecession = false;
  showGumInflammation = false;

  constructor(
    public dialogRef: MatDialogRef<XRayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public injectedData: XRayDialogData,
    private data: DataAccessService
  ) {
    if (isDefined(injectedData.image)) {
      this.image = injectedData.image;
    } else if (isDefined(injectedData.imageUrl)) {
      this.imageUrl = injectedData.imageUrl;
    }
    this.showCaries = injectedData.showCaries;
    this.showBoneloss = injectedData.showBoneloss;
    this.showFracture = injectedData.showFracture;
    this.showPlaque = injectedData.showPlaque;
    this.showGumRecession = injectedData.showGumRecession;
    this.showGumInflammation = injectedData.showGumInflammation;
    this.findingsByTooth = injectedData.findingsByTooth;
    this.findingsByImage = injectedData.findingsByImage;
    this.xrayWidth = injectedData.xrayWidth;
    this.xrayHeight = injectedData.xrayHeight;
    this.index = injectedData.index;
    this.totalCount = injectedData.totalCount;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit() {
    // this._measureContainer();
    // this._subs.sink = fromEvent(window, 'resize').pipe(
    //   debounceTime(500))
    //   .subscribe(() => {
    //     this._measureContainer();
    //   });
    // this._subs.sink = combineLatest(
    //   this.containterDimensions$,
    //   this.xray.containterDimensions$,
    // ).pipe(map([[hostWidth, hostHeight], [contentWidth, contentHeight]]) => {
    //   const scale = Math.min(
    //     containerHeight / this.xrayHeight,
    //     hostWidth / contentWidth
    //   );
    //   this.dialogRef.updateSize(`${Math.round(scale * containerWidth)}px`, `${Math.round(scale * containerHeight)}`);
    // }));
    // this.xray.containterDimensions$.subscribe(([width, height]) => {
    //   this.dialogRef.updateSize(width, height);
    // })
    if (!isDefined(this.image)) {
      this._subs.sink = this.data.images$.subscribe(
        (images: KellsImageBase[]) => {
          this.image = images.find((x) => {
            return x.url === this?.imageUrl;
          });
          if (this.image) {
            this.xrayImageUrl.next(this.image.url);
          }
        }
      );
    } else {
      this.xrayImageUrl.next(this.image.url);
    }

    this._subs.sink = this.data.reportDataLoadComplete$.subscribe(
      ({ report, selectedSession, findingsByTooth, findingsByImage }) => {
        this.selectedSession$.next(selectedSession as string);

        // let tmpImages: KellsImageBase[] = [];
        // for (let fnd of findingsByImage!.values()) {
        //   tmpImages = tmpImages.concat(fnd.images);
        // }
        this.totalImages = (findingsByImage as Map<
          string,
          FindingsByImage
        >).size;
        let i = 1;
        findingsByImage!.forEach((x) => {
          if (x.image_url === this?.image!.url) {
            this.currentIndex = i;
          }
          i++;
        });
        this.images$.next(findingsByImage as Map<string, FindingsByImage>);
      }
    );
  }

  currentIndex = 0;
  totalImages = 0;
  images$ = new BehaviorSubject<Map<string, FindingsByImage>>(
    new Map<string, FindingsByImage>()
  );
  selectedSession$: BehaviorSubject<string> = new BehaviorSubject<string>("");

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
