import { createAnalyticsEvent } from "@kells/apis/analytics";
import { CommonAttributable } from "./base-attributes";

enum EventTypes {
  OpenReportPage = "Open Report Page",
  ReportDownload = "Report download",
  XRaysDownload = "X-Rays download",
  XRayView = "X-Ray view",
  CariesDiagramToothClick = "caries_diagram_tooth_click",
  CariesDiagramXRayTabClick = "caries_diagram_x-ray_tab_click",
  CariesDiagramIntraoralPhotoTabClick = "caries_diagram_intraoral-photo_tab_click",
  CariesSectionQAndAClick = "caries_section_q_a_click",
  BoneLossSectionQAndAClick = "bone_loss_section_q_a_click",
  ScheduleAppointmentBtnClick = "schedule_appointment_btn_click",
}

export const openReportPage = createAnalyticsEvent<CommonAttributable>(
  EventTypes.OpenReportPage
);

export const downloadReport = createAnalyticsEvent<CommonAttributable>(
  EventTypes.ReportDownload
);

export const downloadXrays = createAnalyticsEvent<CommonAttributable>(
  EventTypes.XRaysDownload
);

export const xrayView = createAnalyticsEvent<CommonAttributable>(
  EventTypes.XRayView
);

export const cariesToothClick = createAnalyticsEvent<CommonAttributable>(
  EventTypes.CariesDiagramToothClick
);

export const cariesXRayTabClick = createAnalyticsEvent<CommonAttributable>(
  EventTypes.CariesDiagramXRayTabClick
);

export const cariesIntraoralPhotoTabClick = createAnalyticsEvent<CommonAttributable>(
  EventTypes.CariesDiagramIntraoralPhotoTabClick
);

interface QAndAData extends CommonAttributable {
  panelNumber: number;
}

export const qaCariesSection = createAnalyticsEvent<QAndAData>(
  EventTypes.CariesSectionQAndAClick
);

export const qaBoneLossSection = createAnalyticsEvent<QAndAData>(
  EventTypes.BoneLossSectionQAndAClick
);

export const scheduleAppointmentClick = createAnalyticsEvent<CommonAttributable>(
  EventTypes.ScheduleAppointmentBtnClick
);
