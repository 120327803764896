import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { AnalyticsService } from "@kells/apis/analytics";
import { AppEvents } from "./shared/models/analytics";
import { DataAccessService } from "@kells/apis/data-access";
import { MatDialog } from "@angular/material/dialog";
import { SharedService } from "apps/patient-reporting/src/app/shared/shared.service";

@Component({
  selector: "kpr-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "member-reporting";

  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.recordAppLoad();
  }

  private recordAppLoad() {
    return this.analyticsService.record(AppEvents.appLoaded({}));
  }
}
