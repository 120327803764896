import { createReducer, on, Action } from "@ngrx/store";
import * as LayoutActions from "./layout.actions";
import * as SelectedPatientActions from "@app/core/store/selected-patient/selected-patient.actions";
import { ImageFilters, StoryboardImageFilters } from "@kells/clinic-one/apis";
import { ImageConfirmationActions } from "../image/actions";
import { UserApiActions } from "@kells/clinic-one/data-access/users";
import { AuthActions } from "@kells/clinic-one/auth";
import {
  BonelossFilterSelectionType,
  CalculusFilterSelectionType,
  CariesStageTypes,
  DefectiveRestorationFilterSelectionType,
  FindingFilterSelectionTypes,
  FractureFilterSelectionType,
  InfectionFilterSelectionType,
  MaterialsFilterSelectionType,
  ToothFilterSelectionType,
  PlaqueFilterSelectionType,
  GumRecessionFilterSelectionType,
  GumInflammationFilterSelectionType,
} from "@kells/interfaces/finding";

export const layoutFeatureKey = "layout";

export interface LayoutState {
  shouldUseEnhancedImage: boolean;
  isInsightEnabled: boolean;
  isLabelsEnabled: boolean;
  isConfirmedFindingsVisible: boolean;
  isPredictionsVisible: boolean;
  isSidebarOpen: boolean;
  isSessionListVisible: boolean;
  isPatientSearchVisible: boolean;
  imageFilter: ImageFilters;
  storyboardFilter: StoryboardImageFilters[];
  isInProductTour: boolean;
  imageShowCariesInitial: boolean;
  imageShowCariesModerate: boolean;
  imageShowCariesAdvanced: boolean;
  imageShowMaterial: boolean;
  imageShowTooth: boolean;
  imageShowBoneloss: boolean;
  imageShowFracture: boolean;
  imageShowCalculus: boolean;
  imageShowInfection: boolean;
  imageShowDefectiveRestoration: boolean;
  imageShowPlaque: boolean;
  imageShowGumRecession: boolean;
  imageShowGumInflammation: boolean;
  /** The URL to return to after closing the settings panel */
  settingsExitUrl: string;
  patientReport: {
    opened: boolean;
    sessionId: string | undefined;
    sessionDate: string | undefined;
  };
}

export const initialState: LayoutState = {
  shouldUseEnhancedImage: true,
  isInsightEnabled: true,
  isSidebarOpen: false,
  isLabelsEnabled: false,
  isPatientSearchVisible: false,
  isSessionListVisible: false,
  isConfirmedFindingsVisible: true,
  isPredictionsVisible: true,
  imageFilter: ImageFilters.All,
  storyboardFilter: [],
  isInProductTour: false,
  settingsExitUrl: "/",
  patientReport: {
    opened: false,
    sessionId: undefined,
    sessionDate: undefined,
  },
  imageShowCariesInitial: true,
  imageShowCariesModerate: true,
  imageShowCariesAdvanced: true,
  imageShowMaterial: false,
  imageShowTooth: false,
  imageShowBoneloss: true,
  imageShowFracture: false,
  imageShowCalculus: false,
  imageShowInfection: false,
  imageShowDefectiveRestoration: false,
  imageShowPlaque: false,
  imageShowGumRecession: false,
  imageShowGumInflammation: false,
};

const layoutReducer = createReducer(
  initialState,
  on(
    LayoutActions.patientPageTurnOnInsight,
    LayoutActions.patientPageAutoTurnOnInsight,
    LayoutActions.imageDetailPageTurnOnInsight,
    LayoutActions.imageDetailPageAutoTurnOnInsight,
    LayoutActions.productTourTurnOnInsight,
    (state) => ({
      ...state,
      isInsightEnabled: true,
    })
  ),
  on(
    LayoutActions.patientPageTurnOffInsight,
    LayoutActions.patientPageAutoTurnOffInsight,
    LayoutActions.imageDetailPageTurnOffInsight,
    LayoutActions.imageDetailPageAutoTurnOffInsight,
    LayoutActions.productTourTurnOffInsight,
    SelectedPatientActions.selectPatientFromPatientHome,
    (state) => ({
      ...state,
      isInsightEnabled: false,
      isLabelsEnabled: false,
    })
  ),
  on(LayoutActions.imageDetailShowLabels, (state) => ({
    ...state,
    isLabelsEnabled: true,
  })),
  on(LayoutActions.imageDetailHideLabels, (state) => ({
    ...state,
    isLabelsEnabled: false,
  })),
  on(ImageConfirmationActions.confirmSessionSuccess, (state) => ({
    ...state,
    isInsightEnabled: true,
  })),
  on(LayoutActions.selectStoryboardFilters, (state, { filters }) => ({
    ...state,
    storyboardFilter: filters,
  })),
  on(LayoutActions.toggleImageEnhancement, (state) => ({
    ...state,
    shouldUseEnhancedImage: !state.shouldUseEnhancedImage,
  })),
  on(LayoutActions.toggleSidebar, (state) => ({
    ...state,
    isSidebarOpen: !state.isSidebarOpen,
  })),
  on(LayoutActions.closeSidebar, (state) => ({
    ...state,
    isSidebarOpen: false,
  })),
  on(LayoutActions.openSidebar, (state) => ({
    ...state,
    isSidebarOpen: true,
  })),
  on(LayoutActions.togglePatientSearch, (state, { isVisible }) => {
    return {
      ...state,
      isSidebarOpen: isVisible,
      isPatientSearchVisible: isVisible,
      isSessionListVisible: false,
    };
  }),
  on(LayoutActions.toggleSessionsList, (state, { isVisible }) => {
    return {
      ...state,
      isSidebarOpen: isVisible,
      isPatientSearchVisible: false,
      isSessionListVisible: isVisible,
    };
  }),
  on(AuthActions.loginSuccess, (state) => ({
    ...state,
    isSidebarOpen: true,
  })),
  on(LayoutActions.togglePatientReport, (state, { sessionDate, sessionId }) => {
    const willSessionDateChange =
      sessionDate !== state.patientReport.sessionDate;

      const willSessionIdChange =
      sessionId !== state.patientReport.sessionId;

    const willReportBeOpen =
      willSessionDateChange || willSessionIdChange || !state.patientReport.opened;

    return {
      ...state,
      patientReport: {
        opened: willReportBeOpen,
        sessionId: willReportBeOpen ? sessionId : undefined,
        sessionDate: willReportBeOpen ? sessionDate : undefined,
      },
    };
  }),
  on(LayoutActions.openPatientTemplate, (state, { sessionDate }) => {
    return {
      ...state,
      sessionDate,
    };
  }),
  on(SelectedPatientActions.selectPatientFromPatientHome, (state) => ({
    ...state,
    isSidebarOpen: false,
  })),
  on(
    SelectedPatientActions.selectPatientFromPatientHome,
    SelectedPatientActions.selectPatientFromProductTour,
    LayoutActions.closePatientReport,
    (state) => ({
      ...state,
      patientReport: {
        opened: false,
        sessionId: undefined,
        sessionDate: undefined,
      },
    })
  ),
  on(LayoutActions.selectStoryboardFilters, (state, { filters }) => {
    if (filters.includes(StoryboardImageFilters.Caries)) {
      return {
        ...state,
        imageFilter: ImageFilters.All,
      };
    }
    return state;
  }),

  on(LayoutActions.setImageFindingFilters, (state, { filters }) => {
    const flags = {
      imageShowCariesInitial: false,
      imageShowCariesModerate: false,
      imageShowCariesAdvanced: false,
      imageShowMaterial: false,
      imageShowTooth: false,
      imageShowBoneloss: false,
      imageShowFracture: false,
      imageShowCalculus: false,
      imageShowInfection: false,
      imageShowDefectiveRestoration: false,
      imageShowPlaque: false,
      imageShowGumRecession: false,
      imageShowGumInflammation: false,
    };
    filters.forEach((f: string) => {
      switch (f) {
        case "Initial":
          flags.imageShowCariesInitial = true;
          break;
        case "Moderate":
          flags.imageShowCariesModerate = true;
          break;
        case "Advanced":
          flags.imageShowCariesAdvanced = true;
          break;
        case ToothFilterSelectionType:
          flags.imageShowTooth = true;
          break;
        case BonelossFilterSelectionType:
          flags.imageShowBoneloss = true;
          break;
        case MaterialsFilterSelectionType:
          flags.imageShowMaterial = true;
          break;
        case FractureFilterSelectionType:
          flags.imageShowFracture = true;
          break;
        case CalculusFilterSelectionType:
          flags.imageShowCalculus = true;
          break;
        case InfectionFilterSelectionType:
          flags.imageShowInfection = true;
          break;
        case DefectiveRestorationFilterSelectionType:
          flags.imageShowDefectiveRestoration = true;
          break;
        case PlaqueFilterSelectionType:
          flags.imageShowPlaque = true;
          break;
        case GumRecessionFilterSelectionType:
          flags.imageShowGumRecession = true;
          break;
        case GumInflammationFilterSelectionType:
          flags.imageShowGumInflammation = true;
          break;
      }
    });
    return {
      ...state,
      ...flags,
    };
  }),

  on(
    LayoutActions.displayAllImages,
    SelectedPatientActions.selectPatientFromPatientHome,
    (state) => ({
      ...state,
      imageFilter: ImageFilters.All,
    })
  ),
  on(LayoutActions.displayBitewingImages, (state) => ({
    ...state,
    imageFilter: ImageFilters.Bitewings,
  })),
  on(LayoutActions.beginTouring, (state) => ({
    ...state,
    isInProductTour: true,
  })),
  on(LayoutActions.endTouring, (state) => ({
    ...state,
    isInProductTour: false,
  })),
  on(LayoutActions.enterSettingsPanel, (state, { settingsExitUrl }) => ({
    ...state,
    settingsExitUrl,
  })),
  on(LayoutActions.homeLogoClicked, (state) => ({
    ...state,
    isSidebarOpen: true,
  })),
  on(UserApiActions.turnOnAutoEnhanceSuccess, (state) => ({
    ...state,
    shouldUseEnhancedImage: true,
  })),
  on(UserApiActions.turnOffAutoEnhanceSuccess, (state) => ({
    ...state,
    shouldUseEnhancedImage: false,
  }))
);

export function reducer(state: LayoutState | undefined, action: Action) {
  return layoutReducer(state, action);
}

export const getInsightEnabled = (state: LayoutState) => state.isInsightEnabled;

export const getLabelsEnabled = (state: LayoutState) => state.isLabelsEnabled;

export const getUseImageEnhancementStatus = (state: LayoutState) => {
  return state.shouldUseEnhancedImage;
};

export const getSidebarOpenStatus = (state: LayoutState) => state.isSidebarOpen;
export const isSessionListVisible = (state: LayoutState) =>
  state.isSessionListVisible;
export const isPatientSearchVisible = (state: LayoutState) =>
  state.isPatientSearchVisible;

export const getPatientReportOpenStatus = (state: LayoutState) =>
  state.patientReport.opened;

export const getSessionDateForPatientReport = (state: LayoutState) =>
  state.patientReport.sessionDate;

export const getSessionIdForPatientReport = (state: LayoutState) =>
  state.patientReport.sessionId;

export const getImageFilter = (state: LayoutState) => state.imageFilter;

export const getStoryboardFilters = (state: LayoutState) =>
  state.storyboardFilter;

export const getFindingFilterTypes = (state: LayoutState) => {
  const filters: FindingFilterSelectionTypes[] = [];
  if (state.imageShowBoneloss) {
    filters.push(BonelossFilterSelectionType);
  }
  if (state.imageShowCariesAdvanced) {
    filters.push(CariesStageTypes.Advanced);
  }
  if (state.imageShowCariesInitial) {
    filters.push(CariesStageTypes.Initial);
  }
  if (state.imageShowCariesModerate) {
    filters.push(CariesStageTypes.Moderate);
  }
  if (state.imageShowMaterial) {
    filters.push(MaterialsFilterSelectionType);
  }
  if (state.imageShowTooth) {
    filters.push(ToothFilterSelectionType);
  }
  if (state.imageShowFracture) {
    filters.push(FractureFilterSelectionType);
  }
  if (state.imageShowCalculus) {
    filters.push(CalculusFilterSelectionType);
  }
  if (state.imageShowInfection) {
    filters.push(InfectionFilterSelectionType);
  }
  if (state.imageShowDefectiveRestoration) {
    filters.push(DefectiveRestorationFilterSelectionType);
  }
  if (state.imageShowPlaque) {
    filters.push(PlaqueFilterSelectionType);
  }
  if (state.imageShowGumRecession) {
    filters.push(GumRecessionFilterSelectionType);
  }
  if (state.imageShowGumInflammation) {
    filters.push(GumInflammationFilterSelectionType);
  }

  return filters;
};

export const isInProductTour = (state: LayoutState) => state.isInProductTour;

export const getSettingsExitUrl = (state: LayoutState) => state.settingsExitUrl;
