import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./guards/auth.guard";
import { AppContainerComponent } from "apps/patient-reporting/src/app/shared/components/app-container/app-container.component";

export const rootRoutes: Routes = [
  {
    path: "",
    component: AppContainerComponent,
    children: [
      {
        path: "app",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./home/home.module").then((m) => m.HomeModule),
      },
      { path: "**", redirectTo: "app" },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(rootRoutes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
