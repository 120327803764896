/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SessionIndexed } from "../models/patient.model";
import {
  Image,
  ImageResponse,
  ImageOrderMode,
  RawImageOrderModes,
  ImageLike,
  ImageType,
} from "../models/image.model";
import { keepUnique, isNullable, toImageBase64 } from "@kells/utils/js";
import { isFinding } from "../models/finding.model";
import { ClassificationService } from "./classification.service";
import { ImageFilters } from "../models/image-template.model";
import { flatten } from "lodash-es";
import { forkJoin, from, Observable } from "rxjs";
import {
  catchError,
  concatMap,
  finalize,
  map,
  switchMap,
} from "rxjs/operators";
import {
  CLINIC_ONE_API_BASE_URL,
  CLINIC_ONE_API_VER,
} from "@kells/clinic-one/environments";
import { TemplateType } from "../models";
import { FindingAdapter } from "../adapters/finding.adapter";
import { FindingStatus } from "@kells/interfaces/finding";
import {
  SessionCreationSuccess,
  SessionEventCode,
} from "libs/clinic-one/apis/src/lib/models/session.model";
import * as Sentry from "@sentry/angular-ivy";
import { SubmitReviewJobSurveyRarams } from "libs/clinic-one/data-access/review-jobs/src/lib/models/review-job.model";
import { ReviewJobService } from "@kells/clinic-one/data-access/review-jobs";

interface ImageUploadSuccessResp {
  message: string;
  data: {
    image_id: string;
  };
}

/**
 * @category Service
 */
@Injectable({
  providedIn: "root",
})
export class ImageService {
  private readonly BASE_URL = `${this.baseUrl}/${this.apiVersion}/images`;
  private readonly SESSIONS_BASE_URL = `${this.baseUrl}/${this.apiVersion}/sessions`;

  constructor(
    @Inject(CLINIC_ONE_API_BASE_URL) private baseUrl: string,
    @Inject(CLINIC_ONE_API_VER) private apiVersion: string,
    private http: HttpClient,
    private readonly reviewJobService: ReviewJobService
  ) {}

  private apiUrl = (imageId: string) => `${this.BASE_URL}/${imageId}`;

  private isDragging: boolean;

  public get dragInProgress(): boolean {
    return this.isDragging;
  }

  public set dragInProgress(isDragging: boolean) {
    this.isDragging = isDragging;
  }

  /**
   * Gets information about an image.
   *
   * @param imageId the ID of an image.
   * @returns an [[`Image`]] object containing information about the image.
   * @throws when the provided ID does not exist in the database.
   */
  getImage(imageId: string): Observable<Image> {
    return this.http
      .get<{ data: ImageResponse }>(this.apiUrl(imageId))
      .pipe(map((res) => ImageService.parseImageResponse(res.data)));
  }

  updateImage(imageId: string, payload: Record<string, any>) {
    return this.http.patch(this.apiUrl(imageId), { update_data: payload });
  }

  update(imageId: string, payload: Record<string, any>) {
    return this.http.patch(this.apiUrl(imageId), payload);
  }

  deleteImage(imageId: string) {
    return this.http.delete(this.apiUrl(imageId));
  }

  /**
   * Update the image order in a session, given the updated image ordering
   * and the order mode (whether the order change is requested by a user.)
   */
  updateSessionImageOrders(
    orderMode: ImageOrderMode.Predicted | ImageOrderMode.User,
    orderChanges: { imageId: string; orderId: number }[]
  ) {
    const updatedOrderPayload = orderChanges.map((change) => ({
      img_id: change.imageId,
      order_id: change.orderId,
    }));

    return this.http.patch(`${this.BASE_URL}/orders`, {
      order_mode: orderMode,
      orders: updatedOrderPayload,
    });
  }

  /**
   * Performs image rotation.
   *
   * @param imageId ID of the image to be rotated.
   *
   * @param orientation which direction to rotate 90 degrees to: 'left' or 'right'.
   *
   * @returns the rotated image instance. Rotation changes the coordinates of
   *   all the findings on this image. The updated image instance, returned
   *   here, is helpful for updating the application's state.
   */
  rotate(imageId: string, orientation: "left" | "right"): Observable<Image> {
    interface RotateImageResponse {
      msg: string;
      data: ImageResponse;
    }

    return this.http
      .post<RotateImageResponse>(`${this.apiUrl(imageId)}/rotate`, {
        orientation: orientation === "right" ? "clockwise" : "counterclockwise",
      })
      .pipe(
        map((response) => response.data),
        map(ImageService.parseImageResponse)
      );
  }

  /**
   * Update the template view of session
   * @param templateType is a type of template chosen by user
   * @param entries is an array of objects containing image_id and template_id
   */
  updateImageTemplate(
    templateType: TemplateType,
    entries: { imageId: string; templateId?: number }[]
  ): Observable<any> {
    return forkJoin(
      entries.map((entry) =>
        this.http.patch(this.apiUrl(entry.imageId), {
          template_id: entry.templateId ?? null,
          template_type: templateType,
        })
      )
    );
  }

  /**
   * Create a patient session by supplying the images for that session.
   *
   * @param patientId the patient to create a new session for.
   * @param image one or more files that represent images.
   *
   * @returns an observable that emits an array of image ID strings.
   */
  createPatientSession(
    patientId: string,
    image: File | File[],
    isPhotoScan = false,
    survey?: SubmitReviewJobSurveyRarams
  ): Observable<string[]> {
    const imageFiles: File[] = Array.isArray(image) ? image : [image];

    const sessionParams = {
      patient_id: patientId,
      ...(isPhotoScan ? { event_code: SessionEventCode.PhotoScan } : {}),
    };

    return this.http
      .post<SessionCreationSuccess>(this.SESSIONS_BASE_URL, sessionParams)
      .pipe(
        switchMap((response: SessionCreationSuccess) => {
          const sessionId = response.data.session_id;

          const uploadImages = imageFiles.map((img) =>
            this.uploadImageForPatient(patientId, img, isPhotoScan, sessionId)
          );
          let tasks: Observable<string | void>[] = [...uploadImages];

          if (survey) {
            const submitSurvey = this.reviewJobService.submitReviewJobSurveyAnswers(
              sessionId,
              survey
            );
            tasks = [submitSurvey, ...tasks];
          }

          return forkJoin(tasks).pipe(
            // Return only the results of uploadImageForPatient calls
            map((results) => results.slice(1) as string[]),
            finalize(() => {
              this.markSessionReady(sessionId);
            })
          );
        })
      );
  }

  private markSessionReady(sessionId: string) {
    this.http
      .put(`${this.SESSIONS_BASE_URL}/${sessionId}/ready`, {})
      .subscribe();
  }

  /**
   * Uploads one image for one patient.
   *
   * @returns an observable of the uploaded image's ID.
   */
  uploadImageForPatient(
    patientId: string,
    image: File,
    isPhotoScan = false,
    session_id?: string
  ): Observable<string> {
    const supportedImageFormats = ["png", "jpg", "jpeg"];

    // example values for image.type: `image/png`, `image/gif`.
    const format = image.type.split("/")[1];

    if (!supportedImageFormats.includes(format)) {
      throw new Error(`Image upload for format '${format}' isn't supported.`);
    }

    return from(toImageBase64(image)).pipe(
      concatMap((base64String) =>
        this.http.post<ImageUploadSuccessResp>(this.BASE_URL, {
          patient_id: patientId,
          extension: format,
          image_data_b64_string: base64String,
          is_photo_scan: isPhotoScan,
          session_id,
        })
      ),
      catchError((error) => {
        Sentry.captureException(error);
        Sentry.captureMessage("Failed to upload photo scan.");
        throw error;
      }),
      map((resp) => resp.data.image_id)
    );
  }

  /**
   * Maps session-indexed image responses from server to the Image model.
   * @param patientImagesResponse Session-indexed image response from server.
   */
  static parseImageResponses(
    patientImagesResponse: SessionIndexed<ImageResponse>
  ): Image[] {
    const imageResponses: ImageResponse[] = ImageService.flattenSessionIndexedEntity(
      patientImagesResponse
    );

    return imageResponses.map(ImageService.parseImageResponse);
  }

  /**
   * Given an image, checks whether a user has confirmed it or not.
   *
   * @param image the image to check confirmation status on.
   *
   * @returns `true` if the image has been confirmed, else `false`.
   */
  static isImageConfirmed(image: Image): boolean {
    if (isNullable(image)) return false;

    return image.status === FindingStatus.Confirmed;
  }

  /**
   * Given images from a session, determine if all of them are confirmed.
   *
   * @param images the images from a session.
   *
   * @returns `true` if all images are confirmed, else `false`.
   */
  static isSessionConfirmed(images: Image[]): boolean {
    if (images.length === 0) return false;

    return images.every((image) => ImageService.isImageConfirmed(image));
  }

  /**
   * Given a session-indexed entity, returns an array of the values without
   * the session-indexed keys.
   *
   * Internally, the function unwraps the `SessionIndexed<T>` wrapper interface
   * and returns the internal `T[]`.
   */
  static flattenSessionIndexedEntity<T extends ImageLike>(
    entity: SessionIndexed<T>
  ): T[] {
    return flatten(Object.values(entity));
  }

  /**
   * Parses a server-responded image the Image model used in the app.
   */
  static parseImageResponse(imageResponse: ImageResponse): Image {
    const sessionId =
      imageResponse.sessionId ?? (imageResponse.session as string);

    return {
      id: imageResponse._id,
      url: imageResponse.url,
      templateId: imageResponse.template_id,
      template_type: imageResponse.template_type,
      xrayCaptureTime: imageResponse.created_datetime,
      orderMode: ImageOrderMode.NotInitialized,
      orderId: !isNullable(imageResponse.order_id)
        ? imageResponse.order_id
        : undefined,
      captureTime: ImageService.parseImageCaptureDate(
        imageResponse.image_datetime
      ),
      xrayType: ClassificationService.classificationResolver(
        imageResponse.xray_image_type
      ),
      classifierModelVersion: imageResponse.classifier_model_ver || undefined,
      treatments: imageResponse.treatments || [],
      findings: imageResponse.findings
        .filter(isFinding)
        .filter(keepUnique)
        .map(FindingAdapter.toFinding),
      imageType: imageResponse.image_type,
      status: imageResponse.status,
      sessionId,
    };
  }

  /**
   * Parses order mode in server response into `ImageOrderMode` enum.
   *
   * @param rawOrderMode order mode in server response
   */
  static imageOrderModeResolver(
    rawOrderMode: string | null | undefined
  ): ImageOrderMode {
    switch (rawOrderMode) {
      case RawImageOrderModes.PREDICTED:
        return ImageOrderMode.Predicted;
      case RawImageOrderModes.USER:
        return ImageOrderMode.User;
      default:
        return ImageOrderMode.NotInitialized;
    }
  }

  /**
   * Sorts a session's image entities.
   *
   * The sorted images returned by this function will have the following order:
   * ```txt
   * [
   *  <optional, bitewing images in template order>,  // not always present
   *  <non-bitewing images in chronological order>    // other images
   * ]
   * ```
   */
  static sortImageSession(
    imageSession: Image[]
  ): (Image & { orderId: number })[] {
    const sortedBitewings = ClassificationService.sortByImageFilter({
      images: imageSession,
      filter: ImageFilters.Bitewings,
    }).map((g, orderId) => ({ ...g, orderId }));

    const nonBitewings = imageSession.filter(
      (image) => !sortedBitewings.some((other) => other.id === image.id)
    );

    const sortedNonBitewings = ImageService.sortImagesByCaptureTime(
      nonBitewings
    ).map((g, index) => ({ ...g, orderId: sortedBitewings.length + index }));

    return [...sortedBitewings, ...sortedNonBitewings];
  }

  /**
   * Given an image array, return an array with the same elements but sorted
   * according to their capture time.
   *
   * The function assumes all images have a valid capture time property.
   * If an array where some images do not have capture time defined is provided,
   * the returned array will still contain all the images, but the ordering
   * of images without capture time is undefined.
   *
   * @param images      images to sort, should all include capture time property.
   * @param descending  optionally specify to sort in reverse-chronological order
   */
  static sortImagesByCaptureTime(images: Image[], descending = false): Image[] {
    type ImageWithCaptureTime = Image & { captureTime: Date };

    const ascendSortVal = (a: ImageWithCaptureTime, b: ImageWithCaptureTime) =>
      a.captureTime.getTime() - b.captureTime.getTime();

    const descendSortVal = (a: ImageWithCaptureTime, b: ImageWithCaptureTime) =>
      b.captureTime.getTime() - a.captureTime.getTime();

    const imageHasCaptureTime = (g: Image): g is ImageWithCaptureTime =>
      g.captureTime !== null || g.captureTime !== undefined;

    return images.sort((a, b) => {
      if (imageHasCaptureTime(a) && imageHasCaptureTime(b)) {
        return descending ? descendSortVal(a, b) : ascendSortVal(a, b);
      }
      return 0;
    });
  }

  /**
   * Given an image capture date string, returns a `Date` object representing
   * that date if the date string is formatted correctly, or null otherwise.
   */
  static parseImageCaptureDate(imageCaptureDate: string): Date | null {
    const isDateValid = (d: Date) => !Number.isNaN(d.getTime());

    const constructedDate = new Date(imageCaptureDate);
    return isDateValid(constructedDate) ? constructedDate : null;
  }

  /**
   * Determines if an image session is unordered.
   *
   * Unordered sessions can proceed to be automatically assigned an order.
   */
  static areImagesUnordered(anyImages: Image[]): boolean {
    return anyImages.every(
      ({ orderId }) => !ImageService.validateImageOrderID(orderId)
    );
  }

  /** Given an order ID of an image, determines whether the order ID is valid. */
  static validateImageOrderID(orderId: number | null | undefined) {
    return (
      // cannot be null or undefined
      !isNullable(orderId) &&
      // must be non-negative
      orderId >= 0
    );
  }

  /**
   * Given an image, determines whether it has run through the prediction API.
   *
   * @param image the image to test whether it has been predicted
   */
  static hasImageBeenPredicted(image: Image): boolean {
    const imageWithoutFindings = (g: Image) => g.findings.length === 0;

    if (isNullable(image)) return false;
    if (isNullable(image.findings)) return false;

    return !imageWithoutFindings(image);
  }

  /**
   * Given an images array, return the images that have not been predicted by
   * the predictions API.
   *
   * @param images the array of images to filter from.
   */
  static filterImagesToPredict(images: Image[]) {
    const imageWithoutFindings = (g: Image) => g.findings.length === 0;
    return images
      .filter((g) => imageWithoutFindings(g))
      .filter(
        (g) =>
          !g.isBeingPredicted &&
          g.imageType === ImageType.Xray &&
          g.status !== FindingStatus.Confirmed
      );
  }
}
