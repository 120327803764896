<div class="no-recommendation">
  <div class="no-recommendation__main">
    <div class="no-recommendation__header">
      You don’t have any report to get <br />
      recommendations
    </div>
    <img
      src="assets/no-recommendations.svg"
      alt="No recommendation"
      class="recommendation__image"
    />
  </div>
  <div class="no-recommendation__footer">
    <div class="no-recommendation__footer__header">
      You can get a report using tools below
    </div>
    <div class="no-recommendation__footer__links">
      <div
        (click)="openPhotoScanEmmit(['/app', 'ai-photo-scan'])"
        class="no-recommendation__footer__link"
      >
        <span class="no-recommendation__footer__link-text">AI Dental Scan</span>
        <img src="assets/ai-scan-link.svg" alt="AI Scan" />
      </div>
      <a
        target="_blank"
        href="https://form.jotform.com/232995196179171"
        class="no-recommendation__footer__link"
      >
        <span class="no-recommendation__footer__link-text"
          >Treatment Verification</span
        >
        <img src="assets/report-link.svg" alt="Treatment Verification" />
      </a>
    </div>
  </div>
</div>
