import { AfterViewInit, Component, Input, OnDestroy } from "@angular/core";
import { take } from "rxjs/operators";
import { SubSink } from "subsink";

import { DataAccessService } from "@kells/apis/data-access";
import { RiskLevel } from "../risk-chart/risk-chart.component";

export const INITIAL_TO_MODERATE = [
  "#7b8ccc",
  "#918db7",
  "#a38ea1",
  "#b18f89",
  "#bb9175",
  "#c6925a",
  "#cf9440",
  "#d59620",
];
export const INITIAL_TO_ADVANCED = [
  "#7b8ccc",
  "#8a8ac2",
  "#9787b8",
  "#a285ae",
  "#ac82a5",
  "#b57f9b",
  "#bd7d91",
  "#c47a87",
  "#cb777e",
  "#d27474",
];
export const INITIAL_ONLY = [
  "#35557D",
  "#3A5B85",
  "#3F628E",
  "#456896",
  "#4B6F9E",
  "#5075A6",
  "#567CAF",
  "#5C83B7",
  "#6389BF",
  "#6990C7",
];

export const MODERATE_FROM_INITAL = ["#ddd1c8", "#f2d7a6"];
export const MODERATE_ONLY = [
  "#90561E",
  "#995F1F",
  "#A36820",
  "#AC7121",
  "#B57A23",
  "#BE8324",
  "#C78D25",
  "#CF9726",
  "#D7A127",
  "#DFAB28",
];

export const ADVANCED = [
  "#741413",
  "#831F1A",
  "#922A22",
  "#A0362B",
  "#AD4334",
  "#B9503F",
  "#C45E4A",
  "#CE6D56",
  "#D77C63",
  "#DF8B71",
];

@Component({
  selector: "koa-oral-condition-donut-chart",
  templateUrl: "./oral-condition-donut-chart.component.html",
  styleUrls: ["./oral-condition-donut-chart.component.scss"],
})
export class OralConditionDonutChartComponent
  implements OnDestroy, AfterViewInit {
  @Input() isXraysExist = false;

  chartLevel: RiskLevel | "" = "";
  chartScore = 0;

  hostWidth: string;
  hostHeight: string;

  private _subs = new SubSink();
  constructor(public data: DataAccessService) {}

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  ngAfterViewInit() {
    this._subs.sink = this.data.reportDataLoadComplete$.subscribe(() => {
      const sessionScoreObservable = this.isXraysExist
        ? this.data.sessionXrayScore$
        : this.data.sessionScore$;
      this._subs.sink = sessionScoreObservable
        .pipe(take(1))
        .subscribe((score: number) => {
          this._initScoreData(score);
        });
    });
  }

  private _initScoreData(sessionScore: number) {
    const maxScore = 100;
    if (sessionScore > maxScore) {
      sessionScore = maxScore;
    } else if (sessionScore < 0) {
      sessionScore = 0;
    }

    // const score = maxScore / sessionScore;
    // const score = 10 - sessionScore;
    let score = sessionScore > 0 ? (10 - sessionScore) * 10 : 100;

    // Round up sessionValue to edge location
    // If sessionScore value is less than 10 then round up value to 10
    // If sessionScore value is grater than 90 but less than 100 then round up value to 90
    // If sessionScore value is 100 - leave 100 (99.99 for better UI display)

    if (score <= 10) {
      score = 10;
    } else if (score >= 90 && score < 100) {
      score = 90;
    } else if (score == 100) {
      score = 99.9;
    }

    // Reverse score
    score = 100 - score;

    let statusLevel: RiskLevel | "" = "";
    // risk score diagram has next grades: 0-27 low, 27-72 medium, 72+ high
    let chartScore = score;
    if (score <= 30) {
      statusLevel = "low";

      if (score > 27) {
        chartScore = 27;
      }
    } else if (score > 30 && score < 80) {
      statusLevel = "medium";

      if (score > 72) {
        chartScore = 72;
      }
    } else if (score >= 80) {
      statusLevel = "high";
    }

    this.chartLevel = statusLevel;
    this.chartScore = chartScore;
  }
}
