// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ReviewJobWithPatient } from "libs/clinic-one/data-access/review-jobs/src/lib/models/review-job.model";
import { DATA_HIDDEN_CONFIG } from "../constants/data-hidden-config.constants"
import { DataHiddenSourceFields, HiddenData } from "../models/hidden-data.models";

export const checkIsDataHidden = (patientReviewJob: ReviewJobWithPatient, sourceFields: DataHiddenSourceFields): boolean => {
  const { patient: { insurance_provider }, organizationCode } = patientReviewJob;

  const isHiddenForOrganization = sourceFields.organizations
    .some((organization) => organization.trim().toLowerCase() === organizationCode?.trim().toLowerCase());

  const isHiddenForInsurance = sourceFields.insurances
    .some((insurance) => insurance.trim().toLowerCase() === insurance_provider?.trim().toLowerCase());

    return isHiddenForOrganization || isHiddenForInsurance;
}

export const isDataHidden = (patientReviewJob: ReviewJobWithPatient): HiddenData => ({
  zocDoc: checkIsDataHidden(patientReviewJob, DATA_HIDDEN_CONFIG.ZOC_DOC),
  estimatedCosts: checkIsDataHidden(patientReviewJob, DATA_HIDDEN_CONFIG.ESTIMATED_COSTS),
  treatments: checkIsDataHidden(patientReviewJob, DATA_HIDDEN_CONFIG.TREATMENTS),
});

