import { Component, EventEmitter, Output } from "@angular/core";
import { LinkParams } from "../../shared/interfaces/link-params.interface";
import { Params } from "@angular/router";
import { AnalyticsService } from "@kells/apis/analytics";

import { OpenAiPhotoScanEvent } from "../../../../../../../../../apps/patient-reporting/src/app/shared/models/analytics/home-events";

@Component({
  selector: "kpr-product-recommendation-no-recommendations",
  templateUrl: "./product-recommendation-no-recommendations.component.html",
  styleUrls: ["./product-recommendation-no-recommendations.component.scss"],
})
export class ProductRecommendationNoRecommendationsComponent {
  @Output() public openPhotoScanClicked = new EventEmitter<LinkParams>();

  constructor(private analyticsService: AnalyticsService) {}

  openPhotoScanEmmit(route: string[], routeQuery?: Params) {
    this.analyticsService.record(OpenAiPhotoScanEvent({}));
    this.openPhotoScanClicked.emit({ route, routeQuery });
  }
}
