export enum QueryParameters {
  Portal = "portal",
}

export enum Portals {
  Aetna = "aetna",
  Beanstalk = "beanstalk",
}

export const portalsOrganizations = [
  {
    portal: Portals.Aetna,
    organizationName: "Aetna",
  },
  {
    portal: Portals.Beanstalk,
    organizationName: "Beanstalk Benefits",
  },
];
