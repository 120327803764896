import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Organization } from "@app/core/models/organization.model";
import { AuthService } from "@auth0/auth0-angular";
import { AnalyticsService } from "@kells/apis/analytics";
import { DataAccessService } from "@kells/apis/data-access";
import { Patient, PatientService } from "@kells/clinic-one/apis";
import { keepDefined } from "@kells/utils/observable/observable-operators";
import { OnboardingModalComponent } from "apps/patient-reporting/src/app/home/components/onboarding-modal/onboarding-modal.component";
import { EssentialPatientInfoModalComponent } from "apps/patient-reporting/src/app/shared/components/essential-patient-info-modal/essential-patient-info-modal.component";
import { essentialPatientFields } from "apps/patient-reporting/src/app/shared/constants/essentialPatientFields.constants";
import {
  Portals,
  portalsOrganizations,
  QueryParameters,
} from "apps/patient-reporting/src/app/shared/enums/query.enums";
import { getQueryParamsFromLink } from "apps/patient-reporting/src/app/shared/functions/get-query.functions";
import { UserLoggedInEvent } from "apps/patient-reporting/src/app/shared/models/analytics/global-events";
import {
  InfoCollectionCompletedEvent,
  OnboardingClosedEvent,
} from "apps/patient-reporting/src/app/shared/models/analytics/home-events";
import { SharedService } from "apps/patient-reporting/src/app/shared/shared.service";
import { combineLatest } from "rxjs";
import { distinct, first, map, take } from "rxjs/operators";
import { SubSink } from "subsink";

@Component({
  templateUrl: "./app-container.component.html",
  styleUrls: ["./app-container.component.scss"],
})
export class AppContainerComponent implements OnInit, OnDestroy {
  isUserIdentified = false;

  isAetna = false;
  portal: Portals;

  private _subs = new SubSink();

  public allOrganizations$ = this.data.organizations$;

  private loggedInUserEmail$ = this.auth.user$.pipe(
    keepDefined(),
    map((user) => user.email as string)
  );

  constructor(
    private analyticsService: AnalyticsService,
    private auth: AuthService,
    private patientService: PatientService,
    private data: DataAccessService,
    private dialog: MatDialog,
    private sharedService: SharedService
  ) {}

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  ngOnInit(): void {
    this._subs.sink = this.initializeUser();
    this._subs.sink = this.identifyUserByEmail();
  }

  private initializeUser() {
    return combineLatest([
      this.allOrganizations$,
      this.data.selectedPatient$,
    ]).subscribe(([organizations, patient]) => {
      if (!this.portal) {
        this.setPortal();
      }
      const idOfPortal = this.findCurrentPortalOrganization(organizations)?.id;
      const patientHasPortalInOrganizations = patient.organizations?.some(
        (id) => id === idOfPortal
      );

      if (!patientHasPortalInOrganizations && idOfPortal) {
        const patientOrganizations = [idOfPortal];
        const trackOrganizations = organizations
          .filter((org) => patientOrganizations.includes(org.id))
          .map((org) => org.name);

        this.patientService
          .updatePatient({
            id: patient.id,
            organizations: patientOrganizations,
          })
          .pipe(take(1))
          .subscribe(() => {
            this.identifyUser(patient.email, trackOrganizations);
            this._subs.sink = this.setUserByEmail();
          });
        return;
      } else if (patient.organizations?.length) {
        const trackOrganizations = organizations
          .filter((org) => patient.organizations?.includes(org.id))
          .map((org) => org.name);

        this.identifyUser(patient.email, trackOrganizations);
      } else {
        this.identifyUser(patient.email);
      }

      this.checkEssentialFields(patient);
    });
  }

  findCurrentPortalOrganization(
    organizations: Organization[]
  ): Organization | undefined {
    const organizationName = portalsOrganizations.find(
      (element) =>
        element.portal.toLowerCase() === this.portal.toLocaleLowerCase()
    )?.organizationName;

    return organizations?.find(
      (organization) => organization.name === organizationName
    );
  }

  private identifyUser(email: string | undefined, organizations?: string[]) {
    if (this.isUserIdentified || !email) {
      return;
    }
    this.isUserIdentified = true;
    this.analyticsService.identifyUser(email, {
      organizations: organizations?.join(",").toLowerCase(),
    });
    this.analyticsService.record(UserLoggedInEvent({}));
  }

  private identifyUserByEmail() {
    return this.loggedInUserEmail$.pipe(first()).subscribe((email) => {
      this.data.updateSelectedPatient({ email });
    });
  }

  checkEssentialFields(patient: Patient) {
    const hasAllEssentialData = essentialPatientFields.every(
      (field: string) => !!(patient as any)?.[field]
    );

    if (hasAllEssentialData) {
      if (patient.is_onboarded) {
        return;
      }

      this.openOnboardingModal(patient.id);
      return;
    }
    this.dialog
      .open(EssentialPatientInfoModalComponent, {
        disableClose: true,
        panelClass: "ai-photo-scan-modal",
        backdropClass: "ai-photo-scan-modal-backdrop",
        maxWidth: "620px",
        width: "620px",
        data: patient,
      })
      .afterClosed()
      .subscribe(() => {
        this.analyticsService.record(InfoCollectionCompletedEvent({}));
        this._subs.sink = this.setUserByEmail();
      });
  }

  private openOnboardingModal(patientId: string, is_onboarded = false): void {
    this.dialog
      .open(OnboardingModalComponent, {
        width: "80%",
        maxWidth: "1280px",
        maxHeight: "100%",
        panelClass: "onboarding-modal",
        backdropClass: "onboarding-modal-backdrop",
        data: this.isAetna,
      })
      .afterClosed()
      .subscribe(() => {
        this.analyticsService.record(OnboardingClosedEvent({}));
        if (is_onboarded) {
          return;
        }
        this.patientService
          .updatePatient({
            is_onboarded: true,
            id: patientId,
          })
          .pipe(take(1))
          .subscribe();
      });
  }

  private setPortal() {
    const queryPortal = getQueryParamsFromLink(window.location.href)?.[
      QueryParameters.Portal
    ] as Portals;
    const localStoragePortal = this.sharedService.localStoragePortal;
    this.portal = queryPortal || localStoragePortal;
    this.isAetna = this.portal === Portals.Aetna;
  }

  private setUserByEmail() {
    return this.loggedInUserEmail$.pipe(take(1)).subscribe((email) => {
      this.data.updateSelectedPatient({ email });
    });
  }
}
