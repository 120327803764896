import { InsuranceNames } from "../enums/insurance-names.enum";
import { OrganizationNames } from "../enums/organization-names.enum";
import { DataHiddenConfig } from "../models/hidden-data.models";

export const DATA_HIDDEN_CONFIG: DataHiddenConfig = {
  ESTIMATED_COSTS: {
    organizations: [
      OrganizationNames.LibertyDental,
      OrganizationNames.Villagecare,
      OrganizationNames.KareMobile,
    ],
    insurances: [InsuranceNames.LibertyDental],
  },
  ZOC_DOC: {
    organizations: [],
    insurances: [],
  },
  TREATMENTS: {
    organizations: [
      OrganizationNames.LibertyDental,
      OrganizationNames.Villagecare,
    ],
    insurances: [InsuranceNames.LibertyDental],
  },
};
