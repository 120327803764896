import {
  CanvasLibFinding,
  FindingUpdatePayload,
  KellsFabric,
  RenderableFindingCreationPayload,
} from "@kells/shared-ui/canvas";

/**
 * A CanvasElementRenderer renders elements onto a canvasObj. Each RenderFunction
 * takes in a canvasObj, and the findings used to render new elements; it
 * returns a canvasObj with new elements attached to it.
 *
 * CanvasElementRenderer are used this way:
 * @example
 * const newCanvasObj = renderFunc(oldCanvasObj, findings);
 * // alternatively, apply a series of RenderFunctions
 * const newCanvasObj = renderFunctionsArray.reduce(
 *   (canvas, nextRenderFunc) => nextRenderFunc(canvas, findings),
 *   oldCanvasObj
 * )
 */
export type CanvasElementRenderer = (
  canvasObj: KellsFabric.Canvas,
  findings: CanvasLibFinding[]
) => KellsFabric.Canvas;

/**
 * A CanvasElementCreator creates a new element on the canvas, given that element's
 * specifications. It returns a new object that can be added to the canvasObj.
 *
 * CanvasElementCreator are used this way:
 * @example
 * elementSpecs.map(creatorFunc)
 */
export type CanvasElementCreator = (
  elementSpec: CanvasLibFinding,
  index?: number
) => KellsFabric.Object;

/**
 * Describes all the possible modalities of a canvas.
 */
export enum CanvasModes {
  /** The mode where users can draw new boxes to create new findings. */
  Draw,
  /**
   * The mode where a user can drag their mouse to change image contrast and
   * brightness.
   */
  Adjust,
  /**
   * The mode where views an image without making any changes. A user can use
   * their mousewheel to zoom in, their mouse to drag the image, and more.
   *
   * This is default mode of interaction.
   */
  ReadOnly,
  /** The mode to describe when an image has not been loaded yet. */
  Uninitialized,
}

export enum FindingBrush {
  Caries = "caries",
  Tooth = "tooth",
  Material = "material",
  BoneLoss = "bone_loss",
  Fracture = "fracture",
  Calculus = "calculus",
  Infection = "infection",
  DefectiveRestoration = "defective_restoration",
  Plaque = "plaque",
  GumRecession = "gum_recession",
  GumInflammation = "gum_inflammation",
  None = "none",
}

/**
 * Describes the required properties to initiate a change in the canvas's zoom.
 * This interface is designed to integrate with Fabric.js's zoom method:
 *  http://fabricjs.com/docs/fabric.Canvas.html#zoomToPoint
 */
export interface ZoomEvent {
  /**
   * The next zoom level to be used.
   * To prevent zooming out, it should not go below 1.
   */
  zoom: number;
  /** The coordinate of the next point to zoom  */
  focalPoint: { x: number; y: number };
}

/**
 * Describes all the possible finding update types from the canvas.
 */
export enum FindingUpdateTypes {
  Create,
  Edit,
  Delete,
}

export interface FindingCreationOutput {
  type: FindingUpdateTypes.Create;
  payload: RenderableFindingCreationPayload;
}

export interface FindingEditOutput {
  type: FindingUpdateTypes.Edit;
  payload: {
    id: string | number;
    update: FindingUpdatePayload;
  };
}

export interface FindingDeletionOutput {
  type: FindingUpdateTypes.Delete;
  payload: {
    id: string | number;
  };
}

export type FindingUpdateOutputs =
  | FindingCreationOutput
  | FindingEditOutput
  | FindingDeletionOutput;

export enum SizingPriority {
  /**
   * Canvas will fill the available area and allow user to
   * zoom/pan independently of aspect ratio
   */
  Fill = "Fill",
  /**
   * Maximize image vertically first, then calculate the image's width
   * according to the image's aspect ratio.
   */
  Height = "heightMajor",
  /**
   * Maximize image horizontally first, then calculate the image's width
   * according to the image's aspect ratio.
   */
  Width = "widthMajor",
  /**
   * Prioritize ensuring all images on the same row adhere to the same row height.
   */
  Row = "rowMajor",
  /**
   * Prioritize using the same area for all images, whether they're horizontal
   * or vertical.
   *
   * This means
   *   - the height of a vertical image equals the width of a horizontal image, and
   *   - the width of a vertical image equals the height of a horizontal image.
   */
  SameSize = "sameSize",
}
